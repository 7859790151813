let cdnjs = {
    "picturefill": "https://cdn.jsdelivr.net/npm/picturefill@3.0.3/dist/picturefill.min.js",
    "touchswipe": "https://cdn.jsdelivr.net/npm/jquery-touchswipe@1.6.19/jquery.touchSwipe.min.js",
    "headroom": "https://cdn.jsdelivr.net/npm/headroom.js@0.9.4/dist/headroom.min.js",
    "lightgallery": "https://cdn.jsdelivr.net/npm/lightgallery@1.6.12/dist/js/lightgallery-all.min.js",
    "cookieconsent": "https://packages.newlogic.cz/newlogic-cookieconsent/1.0.0/js/newlogic-cookieconsent.min.js",
    "autosize": "https://cdn.jsdelivr.net/npm/autosize@4.0.2/dist/autosize.min.js",
    "flickity": "https://cdn.jsdelivr.net/npm/flickity@2.1.2/dist/flickity.pkgd.min.js",
    "flickity_sync": "https://cdn.jsdelivr.net/npm/flickity-sync@2.0.0/flickity-sync.min.js",
    "googlemap": "https://maps.googleapis.com/maps/api/js?key=APIKEY",
    "lodash": "https://cdn.jsdelivr.net/npm/lodash@4.17.11/lodash.min.js",
    "recapcha": "https://www.google.com/recaptcha/api.js?render={apikey}"
};